import { MenuIcon, XIcon } from "lucide-react";
import { AnimatePresence, motion } from "motion/react";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";
import { useLockBodyScroll } from "react-use";
import { captureEvent } from "ui/lib/captureEvent";
import { useIsMounted } from "ui/lib/hooks";
import { updateUTMParams } from "ui/lib/misc";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { useApp } from "web/state-containers/app";
import { Action, LinkAction } from "../action";
interface LogoProps {
  src?: string;
}
export const Logo: React.FC<LogoProps> = ({
  src = "/assets/logo/white.svg"
}) => {
  const isMobile = useIsMobile();
  const width = 632 * (isMobile ? 0.18 : 0.2);
  const height = 104 * (isMobile ? 0.18 : 0.2);
  return <div className="self-start" style={{
    width,
    height
  }} data-sentry-component="Logo" data-sentry-source-file="index.tsx">
      <Image src={src} alt="Ladder Logo" width={width} height={height} priority data-sentry-element="Image" data-sentry-source-file="index.tsx" />
    </div>;
};
interface NavProps {
  mobileMenuOpen: boolean;
  toggleMobileMenuOpen: () => void;
  showAdditionalActions?: boolean;
  showWWOD?: boolean;
}
const Nav: React.FC<NavProps> = ({
  toggleMobileMenuOpen,
  mobileMenuOpen,
  showAdditionalActions = true,
  showWWOD
}) => {
  const {
    isHeroCTAInView
  } = useApp();
  const isMobile = useIsMobile();
  return <motion.nav initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} transition={{
    delay: 0.25
  }} className="sticky top-0 z-50 flex items-center justify-between w-full px-4 py-2 text-foreground bg-background lg:px-12 bg-opacity-60 backdrop-blur-xl" data-sentry-element="unknown" data-sentry-component="Nav" data-sentry-source-file="index.tsx">
      <Link href="/" className="" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <Logo data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
      </Link>
      <motion.div initial={{
      opacity: isMobile ? 1 : 0
    }} animate={{
      opacity: isMobile ? 1 : isHeroCTAInView ? 0 : 1
    }} className={cn("flex items-center space-x-4 text-foreground")} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <div className="items-center hidden space-x-6 text-sm lg:flex">
          {showWWOD ? <LinkAction event={{
          action: "WWOD_page__clicked",
          category: "nav"
        }} href={updateUTMParams("/workout/today").toString()} className="uppercase hover:opacity-80">
              <b className="text-primary">Free</b> Workout Of The Day
            </LinkAction> : null}
          {showAdditionalActions ? <>
              <LinkAction event={{
            action: "ladder_shop_clicked",
            category: "nav"
          }} href="https://shop.joinladder.com/" target="_blank" rel="noreferrer" className="uppercase hover:opacity-80">
                Shop
              </LinkAction>
              <LinkAction event={{
            action: "ladder_careers_clicked",
            category: "nav"
          }} href="https://ladder.breezy.hr" target="_blank" rel="noreferrer" className="uppercase hover:opacity-80">
                Careers
              </LinkAction>
            </> : null}
        </div>
        <motion.div initial={{
        opacity: 0
      }} animate={{
        opacity: isHeroCTAInView ? 0 : 1
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <Action at="home.hero.cta" className="flex px-4 py-2 text-xs text-black rounded-full md:text-sm lg:px-6 cta bg-primary" data-sentry-element="Action" data-sentry-source-file="index.tsx" />
        </motion.div>
        {showAdditionalActions ? <button aria-label={mobileMenuOpen ? "close menu" : "open menu"} onClick={toggleMobileMenuOpen} className="flex lg:hidden">
            {mobileMenuOpen ? <XIcon className="w-6 h-6 text-foreground" /> : <MenuIcon className="w-6 h-6 text-foreground" />}
          </button> : null}
      </motion.div>
    </motion.nav>;
};
interface MobileMenuProps {
  mobileMenuOpen: boolean;
  toggleMobileMenuOpen: () => void;
  showWWOD?: boolean;
}
const MobileMenu: React.FC<MobileMenuProps> = ({
  toggleMobileMenuOpen,
  mobileMenuOpen,
  showWWOD
}) => {
  const handleClick = () => {
    captureEvent({
      category: "home.banner.cta",
      action: "find_my_plan_cta_clicked",
      label: "FIND YOUR PLAN"
    });
  };
  return <motion.div initial={{
    opacity: 0,
    y: -25
  }} animate={{
    opacity: 1,
    y: 0
  }} className="fixed inset-0 z-50 w-screen h-screen text-foreground bg-background bg-opacity-80 backdrop-blur-xl" data-sentry-element="unknown" data-sentry-component="MobileMenu" data-sentry-source-file="index.tsx">
      <div className="px-4 py-3 text-xs text-center border-b border-gray-600">
        <span>
          Think less, lift more!{" "}
          <Link onClick={handleClick} className="px-1 font-bold text-primary" href="/quiz?utm_medium=organic&utm_source=website&utm_campaign=homepage&utm_term=banner&utm_content=homepagesurvey" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            FIND YOUR PLAN
          </Link>
        </span>
      </div>
      <motion.nav className="flex items-center justify-between w-full px-4 py-2 text-foreground" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
        <div style={{
        width: 95
      }} className="flex justify-start">
          <Logo data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
        </div>
        <button aria-label={mobileMenuOpen ? "close menu" : "open menu"} onClick={toggleMobileMenuOpen} className="flex lg:hidden">
          {mobileMenuOpen ? <XIcon className="w-6 h-6 text-foreground" /> : <MenuIcon className="w-6 h-6 text-foreground" />}
        </button>
      </motion.nav>
      <div className="px-2 pt-12 mx-2 border-t border-gray-600">
        <div className="flex flex-col items-start space-y-6 text-xl text-left cta">
          {showWWOD ? <LinkAction event={{
          action: "WWOD_page__clicked",
          category: "nav"
        }} href={updateUTMParams("/workout/today").toString()} className="uppercase hover:opacity-80">
              <b>Free</b> Workout Of The Day
            </LinkAction> : null}
          <LinkAction event={{
          action: "pricing_page__clicked",
          category: "nav"
        }} className="uppercase hover:opacity-80" href="/pricing" data-sentry-element="LinkAction" data-sentry-source-file="index.tsx">
            Pricing
          </LinkAction>
          <LinkAction event={{
          action: "ladder_shop_clicked",
          category: "nav"
        }} href="https://shop.joinladder.com/" target="_blank" rel="noreferrer" className="uppercase hover:opacity-80" data-sentry-element="LinkAction" data-sentry-source-file="index.tsx">
            Shop
          </LinkAction>
          <LinkAction event={{
          action: "ladder_careers_clicked",
          category: "nav"
        }} href="https://ladder.breezy.hr" target="_blank" rel="noreferrer" className="uppercase hover:opacity-80" data-sentry-element="LinkAction" data-sentry-source-file="index.tsx">
            Careers
          </LinkAction>

          <Action at="home.hero.cta" defaultClasses="text-xl" className="text-left uppercase text-primary" data-sentry-element="Action" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </motion.div>;
};
type NavContainerProps = Omit<NavProps & MobileMenuProps, "mobileMenuOpen" | "toggleMobileMenuOpen">;
const NavContainer: React.FC<NavContainerProps> = props => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isMounted = useIsMounted();
  useLockBodyScroll(mobileMenuOpen);

  // Reset menu state on route change
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [pathname, searchParams]);
  const toggleMobileMenuOpen = () => setMobileMenuOpen(!mobileMenuOpen);
  if (!isMounted) return null;
  return <>
      <Nav mobileMenuOpen={mobileMenuOpen} toggleMobileMenuOpen={toggleMobileMenuOpen} {...props} data-sentry-element="Nav" data-sentry-source-file="index.tsx" />
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="index.tsx">
        {mobileMenuOpen ? <MobileMenu mobileMenuOpen={mobileMenuOpen} toggleMobileMenuOpen={toggleMobileMenuOpen} {...props} /> : null}
      </AnimatePresence>
    </>;
};
export default NavContainer;