"use client";

import { motion } from "motion/react";
import Link from "next/link";
import { useSearchParams } from "next/navigation"; // Use `useSearchParams` for App Router
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { captureEvent } from "ui/lib/captureEvent";
import { useIsMounted } from "ui/lib/hooks";
import { updateUTMParams } from "ui/lib/misc";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { accessVariant } from "web/components/text-node";
import { useExperiment } from "web/state-containers/experiment";
import { useModals } from "./modals/container";
const MotionLink = motion.create(Link);
interface LinkActionProps extends React.ComponentPropsWithoutRef<typeof Link> {
  event: {
    category?: string;
    action: string;
    [key: string]: any;
  };
  href: string;
  children: React.ReactNode;
}
export const LinkAction: React.FC<LinkActionProps> = ({
  children,
  event,
  href,
  ...props
}) => {
  const handleClick = () => {
    captureEvent({
      ...event,
      label: children
    });
  };
  return <Link href={href} onClick={handleClick} {...props} data-sentry-element="Link" data-sentry-component="LinkAction" data-sentry-source-file="action.tsx">
      {children}
    </Link>;
};
interface ActionProps extends Omit<React.ComponentPropsWithoutRef<typeof MotionLink>, "href"> {
  at: string;
  defaultClasses?: string;
  className?: string;
  event?: {
    category?: string;
    action?: string;
    label?: string;
    [key: string]: any;
  };
}
const blackFridayAnnualLink = "https://apps.apple.com/redeem?ctx=offercodes&id=1502936453&code=BLACKFRIDAY99";
const blackFridayMonthlyLink = "https://apps.apple.com/redeem?ctx=offercodes&id=1502936453&code=Q50OFFBF";
const blackFridayMonthly12Link = "https://apps.apple.com/redeem?ctx=offercodes&id=1502936453&code=BLACKFRIDAY50";
export const Action = React.memo<ActionProps>(({
  at,
  defaultClasses = "text-lg rounded-full cta",
  className,
  event,
  ...props
}) => {
  const {
    variant
  } = useExperiment();
  const searchParams = useSearchParams(); // For query params in the App Router
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  const {
    toggleShowQRModal,
    setValue,
    setTitle,
    setDescription
  } = useModals();
  const isMobileSafe = useMemo(() => typeof window !== "undefined" ? isMobile : false, [isMobile]);
  const accessedVariant = useMemo(() => accessVariant(at, variant as string), [at, variant]);
  const getHref = () => {
    if (variant === "BlackFridayAnnual") {
      return blackFridayAnnualLink;
    } else if (variant === "BlackFridayMonthly") {
      return blackFridayMonthlyLink;
    } else if (variant === "BlackFridayMonthly12") {
      return blackFridayMonthly12Link;
    } else {
      const initial = accessedVariant.link || (accessedVariant.href ? updateUTMParams(accessedVariant.href).href : "");
      return initial;
    }
  };
  const [href, setHref] = useState<string>(getHref);

  // Update href based on variant
  useEffect(() => {
    setHref(getHref());
  }, [variant, accessedVariant]);

  // Extract promoCode from search params
  const promoCode = searchParams?.get("promoCode") ?? "none";

  // General event for Black Friday
  const sendBlackFridayEvent = useCallback(() => {
    captureEvent({
      action: "IAP Claim Offer Clicked",
      category: "IAP",
      value: "cta clicked",
      promoCode: promoCode,
      path: window.location.pathname + window.location.search
    });
  }, [promoCode]);

  // Default event for other actions
  const sendDefaultEvent = useCallback(() => {
    captureEvent({
      category: at,
      action: "find_my_plan_cta_clicked",
      label: accessedVariant.label,
      ...event
    });
  }, [at, accessedVariant.label, event]);
  const handleClick = useCallback(async (e: any) => {
    if (["BlackFridayAnnual", "BlackFridayMonthly", "BlackFridayMonthly12"].includes(variant)) {
      sendBlackFridayEvent();
      if (!isMobileSafe) {
        setValue(href);
        toggleShowQRModal();
      }
    } else {
      sendDefaultEvent();
    }
    if (props.onClick) props.onClick(e);
  }, [variant, isMobileSafe, sendBlackFridayEvent, sendDefaultEvent, href, setValue, toggleShowQRModal, props.onClick]);
  if (!href) return null;
  if (!isMounted) return null;

  // Render appropriate link or button
  if (["BlackFridayAnnual", "BlackFridayMonthly", "BlackFridayMonthly12"].includes(variant) && !isMobileSafe) {
    return <button onClick={handleClick} className={cn("relative inline-block cursor-pointer", defaultClasses, className)}>
          {accessedVariant.label}
        </button>;
  }
  return <MotionLink {...props} href={href} onClick={handleClick} aria-label={accessedVariant.label} className={cn("relative inline-block cursor-pointer", defaultClasses, className)} whileHover={{
    scale: 1.025
  }} whileTap={{
    scale: 0.975
  }}>
        {accessedVariant.label}
      </MotionLink>;
});
Action.displayName = "Action";